import React, { Dispatch, FC, SetStateAction } from 'react';
import './address-document.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { handleUploadDocuments } from '../../../utils/document-uploads';
import { fireGaEvent } from '../../../utils/ga-event';
import { setAddressDocuments } from '../../../redux/Applicant/applicant.actions';
import ActivityDocumentsUploadCard from '../../activity/activity-document-upload-card/activity-document-upload-card.component';

type Props = {
	setShowForm: Dispatch<SetStateAction<boolean>>;
	setShowUpload: Dispatch<SetStateAction<boolean>>;
	handleBack: (origin: string) => void;
	setHasFile: Dispatch<SetStateAction<boolean>>;
	pageOrigin: string;
};

const AddressDocument: FC<Props> = ({ setShowUpload, setHasFile }) => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.applicant.applicant,
	);
	const addressDocument = useSelector(
		(state: RootState) => state.applicant.addressDocuments,
	);

	fireGaEvent('Address History', 'Upload a document Page');

	const handleUpload = (file: any) => {
		fireGaEvent('Address History', 'Clicked upload document button');
		return handleUploadDocuments(
			file,
			applicant.workerUuid,
			'proofOfAddress',
			dispatch,
			'workerDocuments',
			undefined,
		)
			.then((res: any) => {
				dispatch(setAddressDocuments([res.data.data]));
				setShowUpload(true);
				setHasFile(true);
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	return (
		<section className='address-document-container'>
			<h6>Examples of documents we accept:</h6>
			<p className='address-document-text'>Please be aware: we cannot accept a provisional licence</p>
			<ul className='document-type-links'>
				<li>
					<p>Utility bill (from the last 3 months)</p>
				</li>
				<li>
					<p>Bank statement (from the last 3 months)</p>
				</li>
				<li>
					<p>Drivers licence</p>
				</li>
				<li>
					<p>Credit card statement (from the last 3 months)</p>
				</li>
				<li>
					<p>Council tax bill (from the last 12 months)</p>
				</li>
				<li>
					<p>P45/P60 (from the last 3 months)</p>
				</li>
				<li>
					<p>Mortgage statement (from the last 3 months)</p>
				</li>
			</ul>

			<ActivityDocumentsUploadCard
				handleUpload={handleUpload}
				documents={addressDocument}
				requiredDocsNumber={1}
				isActivityCard={false}
			/>
		</section>
	);
};

export default AddressDocument;
